<template>
  <div class="content flex">
    <div class="edit">
      <h3 class="title" v-if="showName && type > 1">上级类目：{{showName}}</h3>
      <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
        <el-form-item label="类目名称：" prop="name">
          <el-input v-model="edit.name" :maxlength="10" placeholder="请输入名称"></el-input>
          <span class="tips">类目名称建议4字，最多10字</span>
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="edit.sort" placeholder="请输入排序数" type="number"></el-input>
          <span class="tips">排序号越小，排序位置越前，可增加曝光机会</span>
        </el-form-item>
        <el-form-item label="上传图片：" prop="imgurl" v-if="type === 3">
          <template>
            <div class="upload">
              <Upload id="logo" src="category" @success="onLogoUpload">
                <div class="logo" v-if="edit.imgurl">
                  <img :src="edit.imgurl" alt="logo" width="100%">
                  <span class="exchange">更换图片</span>
                </div>
                <div class="upload-box flex-center" v-else>
                  <i class="el-icon-plus"></i>
                  <span class="text">点击上传</span>
                </div>
              </Upload>
            </div>
            <span class="tips">建议图片尺寸186x186像素，图片需为白底，且大小 &lt;=100kb，支持jpg、png格式</span>
          </template>
        </el-form-item>
        <el-form-item label="营业状态：">
          <el-radio-group v-model="edit.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onConfirm">确定</el-button>
          <el-button @click="onCancel">取消</el-button>
          <el-button @click="onDelete" v-if="id">删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="demo">
      <h3 class="title">{{type | typename }}</h3>
      <img :src="src" alt="demo" width="298px">
    </div>
  </div>
</template>

<script>
import Upload from '@/components/Upload'
export default {
  name: 'CateGroyAdd',
  components: {
    Upload
  },
  data() {
    return {
      pid: null,
      id: null,
      type: null, // 1父类目 2子类目 3叶子类目
      edit: {
        name: '',
        sort: '',
        status: 1,
        logo: {
          w: 100,
          h: 100,
          url: ''
        },
        imgurl: ''
      },
      rules: {
        name: [{required: true, message: '请输入名称'}],
        imgurl: [{required: true, message: '请上传图片'}]
      },
      detail: null
    }
  },
  computed: {
    showName() {
      const name = this.categoryData.map(item => {
        return item.categoryName
      })
      return name.join('>')
    },
    src() {
      const url = ['', 'one', 'two', 'two']
      return `https://ddimage.topboom0912.com/admin_static_img/demo/categroy_${url[this.type]}.png`
    },
    categoryData() {
      return this.$store.state.categoryData
    }
  },
  filters: {
    toName(type) {
      if (type === 1) return '添加子类目'
      if (type === 2) return '添加叶子类目'
      return ''
    },
    typename(type) {
      if (type === 1) return '添加类目模板示例'
      if (type === 2) return '添加子类目模板示例'
      if (type === 3) return '添加叶子类目模板示例'
    }
  },
  mounted() {
    this.type = this.$route.query.type * 1 || null
    this.id = this.$route.query.id * 1 || null
    this.pid = this.$route.query.pid * 1 || null
    if (this.id) this.getDetail()
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id
      }
      const url = 'admin/category/detail'
      const data = await this.$https.get(url, {params})
      if (!data) return
      this.edit.name = data.categoryName
      this.edit.status = data.status
      this.edit.sort = data.sort
      this.edit.imgurl = this.$img(data.img)
      data.img = JSON.parse(data.img)
      this.detail = data
    },
    onLogoUpload(url) {
      this.edit.imgurl = url
    },
    async onConfirm() {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      const {name, sort, status, logo, imgurl} = this.edit
      if (logo.url !== imgurl) logo.url = imgurl
      const url = 'admin/category/edit'
      const params = {
        category_name: name,
        status,
        sort,
        img: JSON.stringify(logo),
        id: this.id,
        pid: this.pid
      }
      const data = await this.$https.post(url, params)
      if (data) {
        this.clear()
        this.$message.success(this.id ? '编辑成功' : '添加成功')
        this.$router.go(-1)
      }
    },
    onCancel() {
      this.clear()
      this.$router.go(-1)
    },
    async onDelete() {
      const url = 'admin/category/edit'
      this.detail.img = JSON.stringify(this.detail.img)
      const params = Object.assign({}, this.detail, {status: -1})
      const data = await this.$https.post(url, params)
      if (data) {
        this.clear()
        this.$message.success('删除成功')
        this.$router.go(-1)
      }
    },
    clear() {
      this.edit.name = ''
      this.edit.sort = ''
      this.edit.status = 1
      this.edit.imgurl = ''
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
.edit {
  padding-right: 70px;
  border-right: 1px solid #E8E8E8;
  box-sizing: border-box;
  .title {
    padding-bottom: 32px;
  }
  .tips {
    padding-top: 8px;
    color: rgba(0, 0, 0, 0.45);
  }
  .tb-form {
    width: 610px;
    .upload {
      width: 104px;
      display: flex;
      flex-direction: column;
      line-height: 1;
      &-box {
        width: 100%;
        padding: 24px 0;
        flex-direction: column;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        cursor: pointer;
        .text {
          padding-top: 8px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .logo {
        position: relative;
        width: 100%;
        height: 104px;
        overflow: hidden;
        cursor: pointer;
        .exchange {
          position: absolute;
          display: block;
          width: 100%;
          height: 28px;
          line-height: 28px;
          bottom: 0;
          left: 0;
          font-size: 12px;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.45);
          text-align: center;
        }
      }
    }
  }
}
.demo {
  padding-left: 70px;
  .title {
    padding-bottom: 16px;
  }
}
</style>
